import React from "react"
import "./animation.css"

export default class BgAnimation extends React.Component {
    render() {
        return (
            <div className="wrapper">
                {/* <div class="container"> */}
                <ul className="bgbubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        )
    }
}
