import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { green } from "@material-ui/core/colors"
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { navigate } from "gatsby"
import React from "react"
import swal from "sweetalert"

import { approvalRefuse, approvalWait, loginError } from "../entity/alert/swalErrorList"
import BgAnimation from "../presentation/atoms/bgAnimation"
import { userRepository } from "../repository/action"

import Theme from "@/entity/theme/theme"
import signInIcon from "@/images/mainPic/signInIcon.png"

const theme = createMuiTheme({
    palette: {
        primary: green,
    },
})

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: "#fff",
            zIndex: 1000,
        },
    },
    papper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "30% 20px",
        zIndex: 1000,
    },
    signIn: {
        marginTop: theme.spacing(31),
    },
    app: {
        marginTop: theme.spacing(31),
    },
    date: {
        position: "fixed",
        right: "3%",
        top: "1%",
        fontSize: "20px",
        zIndex: 400,
        display: "inherit",
    },
    user: {
        display: "flex",
        position: "fixed",
        right: "7%",
        top: "9px",
        fontSize: "20px",
    },
    media: {
        height: "40%",
        width: "40%",
        zIndex: "-1",
    },
    name: {
        display: "flex",
        top: "0.2em",
        position: "relative",
    },
    avatar: {
        marginBottom: theme.spacing(5),
        backgroundColor: "green",
        fontSize: "35px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },
    submit: {
        backgroundColor: Theme.color.primaryColor,
        color: "white",
        fontWeight: "bold",
    },
    ver: {
        position: "fixed",
        bottom: 0,
        right: "3%",
    },
    circle: {
        display: "flex",
        margin: theme.spacing(10, 0, 2),
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    },
    circleColor: {
        color: "#00695c",
        width: "90px",
        height: "90px",
        margin: "auto",
    },
}))

function SignIn() {
    const classes = useStyles()
    const [loginInfo, setLoginInfo] = React.useState({})
    const [signInName, setSignInName] = React.useState("Sign in")
    const [signInButtonFlag, setSignInButtonFlag] = React.useState(false)
    // const [checked, setChecked] = React.useState(false)
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    })

    const toRegister = () => {
        navigate("/register")
    }
    const toPasswordrecovery = () => {
        navigate("/passwordrecovery")
    }

    const toMain = (data) => {
        let setUserjson = JSON.stringify(data.user_info)
        localStorage.setItem(["loginUser"], [setUserjson])
        switch (data.user_info.type) {
            case "member":
                navigate("/", {
                    state: {
                        loginUser: data.user_info,
                        contacts: data.contact_array.reverse(),
                        memberData: data.memberData,
                    },
                })
                break
            case "leaders":
                navigate("/", {
                    state: {
                        loginUser: data.user_info,
                        contacts: data.contact_array.reverse(),
                        memberData: data.memberData,
                    },
                })
                break
            case "ob":
                navigate("/", {
                    state: {
                        loginUser: data.user_info,
                        contacts: data.contact_array.reverse(),
                        memberData: data.memberData,
                    },
                })
                break
            case "administrator":
                navigate("/", {
                    state: {
                        loginUser: data.user_info,
                        contacts: data.contact_array.reverse(),
                        memberData: data.memberData,
                    },
                })
                break
            default:
                swal(loginError)
                setSignInName("Sign in")
                setSignInButtonFlag(false)
                break
        }
    }

    const loginChange = (name) => (event) => {
        setLoginInfo({ ...loginInfo, [name]: event.target.value })
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const loginCheck = () => {
        setSignInName("Signing in...")
        setSignInButtonFlag(true)
        userRepository
            .login(loginInfo)
            .then((data) => {
                if (data.memberData) {
                    switch (Number(data.user_info.permission)) {
                        case 1:
                            swal(approvalWait).then(() => {
                                setSignInName("Sign in")
                                setSignInButtonFlag(false)
                            })
                            break
                        case 2:
                            toMain(data)
                            break
                        case 3:
                            swal(approvalRefuse).then(() => {
                                setSignInName("Sign in")
                                setSignInButtonFlag(false)
                            })
                            break
                    }
                } else {
                    swal(loginError)
                    setSignInName("Sign in")
                    setSignInButtonFlag(false)
                }
            })
            .catch(() => {
                swal(loginError)
                setSignInName("Sign in")
                setSignInButtonFlag(false)
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <BgAnimation />
            <CssBaseline />
            <div className={classes.papper}>
                <img className={classes.media} src={signInIcon} />
                <Typography
                    component="h3"
                    variant="h4"
                    style={{ margin: "10px auto", fontSize: "1.725rem" }}
                >
                    Sign in to HitRate
                </Typography>
                <Card variant="outlined" style={{ width: "320px" }}>
                    {/* {linearAnimation && <LinearProgress />} */}
                    <CardContent style={{ padding: "16px 16px 0px" }}>
                        <form className={classes.form} noValidate>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label="メールアドレス"
                                    name="email"
                                    inputProps={{ style: { padding: "15px 13px" } }}
                                    onChange={loginChange("email")}
                                />
                                <div style={{ margin: "8px 0px" }}>
                                    <FormControl variant="outlined" style={{ width: "100%" }}>
                                        <InputLabel htmlFor="outlined-adornment-password">
                                            パスワード
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            label="パスワード"
                                            labelWidth={80}
                                            inputProps={{ style: { padding: "15px 13px" } }}
                                            type={values.showPassword ? "text" : "password"}
                                            onChange={loginChange("password")}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>

                                <Button
                                    size="small"
                                    color="inherit"
                                    onClick={toPasswordrecovery}
                                    style={{
                                        color: Theme.color.thirdColor,
                                    }}
                                >
                                    パスワードを忘れた方はこちら
                                </Button>
                            </ThemeProvider>
                        </form>
                    </CardContent>
                    <CardActions style={{ padding: "16px" }}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={loginCheck}
                            disabled={signInButtonFlag}
                        >
                            {signInName}
                        </Button>
                        <br />
                    </CardActions>
                    <div className={classes.ver}>Ver.10.11</div>
                    <div style={{ fontSize: "13px" }}>
                        <div
                            style={{
                                margin: "0px 16px 16px",
                                display: "flex",
                                color: Theme.color.thirdColor,
                            }}
                        >
                            <Button size="small" color="inherit" onClick={toRegister}>
                                新規登録はこちら
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default SignIn
